import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {LabelPipe} from './pipe/label.pipe';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';

import {LoginBtnComponent} from './components/user-auth/buttons/login-btn/login-btn.component';
//import {LoginModalComponent} from './components/user-auth/login-modal/login-modal.component';
import {LogoutBtnComponent} from './components/user-auth/buttons/logout-btn/logout-btn.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {SigninBtnComponent} from './components/user-auth/buttons/signin-btn/signin-btn.component';
import {LoaderModule} from 'amg-fe-angular/modules/loader/loader.module';
import {IsExternalLinkPipe} from 'amg-fe-angular/pipes/is-external-link.pipe';
import {DateFormattedPipe} from 'amg-fe-angular/pipes/date-formatted.pipe';


@NgModule({
  declarations: [
    LoginBtnComponent,
    //LoginModalComponent,
    LogoutBtnComponent,
    IsExternalLinkPipe,
    DateFormattedPipe,
    LabelPipe,
    SafeHtmlPipe,
    SigninBtnComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        LazyLoadImageModule,
        LoaderModule,
    ],
  exports: [
    LoginBtnComponent,
    //LoginModalComponent,
    LogoutBtnComponent,
    LabelPipe,
    DateFormattedPipe,
    IsExternalLinkPipe,
    SafeHtmlPipe,
    SigninBtnComponent,
  ]
})
export class SharedModule {
}
