import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnInit,
  Inject,
  OnDestroy,
  Renderer2,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import {CommonData} from '../../core/types/wordpress/common-data.type';
import {WordpressService} from '../../core/services/wordpress.service';
import {Subscription, fromEvent, Subject} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import {configs} from '../../core/configs';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserState} from 'amg-fe-angular/types/payments/user-auth.type';
import {UserAuthSsoService} from 'amg-fe-angular/services/user-auth/user-auth-sso.service';
//import {UserAuthSsoUrbanService} from '../../core/services/user-auth-sso-urban.service';
import {distinctUntilChanged, map, pairwise, takeUntil, throttleTime} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

export enum Direction {
  None = 'None',
  Up = 'Up',
  Down = 'Down'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  private commonDataSubscription: Subscription;
  public commonData: CommonData;
  public isLoggedIn: boolean;
  public menuOpened = false;
  public translateOpened = false;
  public myAccountLink = configs.streamPayments.protocol + environment.streamPayments.domain + configs.streamPayments.urls.myAccount;
  public searchOpened = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public slideHeader2InAtPosition = 100;


  private headerHideSubscription: Subscription;
  scroll = true;

  @ViewChild('searchLabelMobile', {static: false}) labelMobile;
  @ViewChild('searchLabelDesktop', {static: false}) labelDesktop;
  textSearch: string;

  @ViewChild('headerElementRef', {static: true}) headerElementRef: ElementRef<HTMLElement>;

  form: FormGroup = new FormGroup({
    search: new FormControl()
  });

  constructor(
    private router: Router,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document,
    private cd: ChangeDetectorRef,
    private wordpressService: WordpressService,
    public userAuthService: UserAuthSsoService,
    private renderer: Renderer2) {
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    const numb = window;
    const scroll$ = fromEvent(numb, 'scroll').pipe( // if the scroll events happen on your window you could use 'window' instead of 'content' here
      throttleTime(10),
      map(() => window.scrollY), // if you used 'window' above replace 'content.scrollTop' with 'window.pageYOffset'
      pairwise(),
      map(([y1, y2]): Direction => {
        return (y2 < y1 ? Direction.Up : (y2 > this.slideHeader2InAtPosition ? Direction.Down : Direction.None));
      }),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    );
    // subscribe to the UP / DOWN scroll direction stream and set the header state accordingly
    scroll$.subscribe(dir => {
      if (dir === Direction.Down) {
        this.renderer.addClass(document.body.parentElement, 'close-top-menu');
      } else {
        this.renderer.removeClass(document.body.parentElement, 'close-top-menu');
      }
    });
  }


  ngOnInit() {

    this.scroll = true;

    this.commonDataSubscription = this.wordpressService.getCommonData<CommonData>()
      .subscribe(data => {
        if (data) {
          this.commonData = data;
          this.cd.markForCheck();
        }
      });

    this.userAuthService.userStateObservable.subscribe(userState => {
      this.isLoggedIn = userState.state !== UserState.notLoggedIn;
      this.cd.markForCheck();
    });

    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
      });
  }

  toggleSearch(inputElement: HTMLInputElement) {
    if (this.searchOpened) {
      this.closeSearch();
    } else {
      this.openSearch(inputElement);
    }
  }

  openSearch(inputElement: HTMLInputElement) {
    this.searchOpened = true;
    // window.scrollTo(0, 0);
    this.renderer.addClass(this.document.body.parentElement, 'overlay-opened');

    setTimeout(() => {
      inputElement.focus();
    }, 500);
  }

  closeSearch() {
    this.searchOpened = false;
    this.renderer.removeClass(this.document.body.parentElement, 'overlay-opened');
  }

  showOrHideManually(event) {
    this.textSearch = this.form.get('search').value;
    if (this.textSearch === '') {

      this.labelMobile.nativeElement.classList.remove('hide');
      this.labelMobile.nativeElement.classList.add('show');

      this.labelDesktop.nativeElement.classList.remove('hide');
      this.labelDesktop.nativeElement.classList.add('show');

    } else {

      this.labelMobile.nativeElement.classList.remove('show');
      this.labelMobile.nativeElement.classList.add('hide');

      this.labelDesktop.nativeElement.classList.remove('show');
      this.labelDesktop.nativeElement.classList.add('hide');
    }
  }

  submitSearch(event: Event, inputElement: HTMLInputElement) {
    event.preventDefault();
    const search = this.form.get('search').value;
    this.closeSearch();
    if (search !== '') {
      this.form.setValue({
        search: '',
      });
      inputElement.blur();

      this.router.navigate(['/browse'], {queryParams: {search}});
    }
  }

  public doLogout(event) {

    event.preventDefault();
    this.userAuthService.streamPaymentsLogOut(null, () => {
      //this.cookieService.delete(configs.sso.tokenKey);
      this.cookieService.deleteAll();
      //this.closeMenu();
    });
  }

  public openMenu() {
    this.menuOpened = true;
    // window.scrollTo(0, 0);
    this.renderer.addClass(document.body.parentElement, 'overlay-opened');
    // trigger refresh html...
    this.cd.markForCheck();
  }

  public closeMenu(keepOverlay?: boolean) {
    this.menuOpened = false;

    if (keepOverlay !== true) {
      setTimeout(() => {
        this.renderer.removeClass(document.body.parentElement, 'overlay-opened');
      }, 700);
    }

    // trigger refresh html...
    this.cd.markForCheck();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    if (window.innerWidth >= 1280 && this.menuOpened) {
      this.closeMenu();
    }
  }

  public toggleTranslate(): void {
    this.translateOpened = !this.translateOpened;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {
    this.commonDataSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.headerHideSubscription.unsubscribe();

    // Scroll
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

