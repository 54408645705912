<footer>
  <div class="top">
    <div class="sponsors" *ngIf="commonData.footer_sponsors">
      <div class="box-img" *ngFor="let item of commonData.footer_sponsors">
        <a target="_blank" [href]="item.link">
        <img class="lazyload-image" [defaultImage]="defaultImage" [lazyLoad]="item.image" alt="sponsor"/>
        </a>
      </div>
    </div>
    <div class="logo-avfc">
        <div class="avfc-content">
            <div class="img"></div>
            <div class="link-back">
                <a [attr.href]="commonData.avfc_link" target="_blank" [textContent]="commonData.footer_avfc_label"></a>
            </div>
        </div>
    </div>
    <div class="social-links">
      <div *ngFor="let item of commonData.social_links">
        <a [attr.href]="item.link" target="_blank" [ngClass]="social(item.link)" [textContent]="social(item.link)"></a>
      </div>
    </div>
  </div>

  <div class="links">
    <div *ngFor="let item of commonData.footer_links">
      <ng-container *ngIf="item.link | isExternalLink; else internal">
        <a [attr.href]="item.link" [textContent]="item.label"></a>
      </ng-container>
      <ng-template #internal>
        <a [routerLink]="item.link" [textContent]="item.label"></a>
      </ng-template>
    </div>
  </div>

  <div class="copyright">
    <p [textContent]="commonData.footer_copyright"></p>
  </div>
</footer>
