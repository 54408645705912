export const configs = {
  google: {
    gtm: 'GTM-WNHZM46',
  },
  carouselItemSize: 10,
  playlistPageSize: 20,
  generalContentPageSize: 24,
  titleSite: 'VillaTV',


  streamPayments: {
    protocol: 'https://',
    urls: {
      myAccount: 'account',
      start: 'sso/start/cmV0dXJudXJsOi9hY2NvdW50/?lang=en',
      // start: 'sso/start/cmV0dXJudXJsOi9zc28vc3RhcnRiYXNrZXQvMzk0MmRhOTAtZTVjYS00YzUwLWI0ZWYtNjUyNDhmN2ZiYTBhP2xhbmc9ZW4~/?lang=en'
    }
  },

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3000221',
    uiConfId: '30027907',
    uiConfIdDRM: '30023669',
    flashvars: {
      'volumeControl.order': 70,
      'chromecast.order': 60,
      IframeCustomPluginCss1: '/assets/css/video-player.css'
    },
  },

  sso: {
    // provider: 'CognitoIdentityServiceProvider.',
    cognito_application_id: '2ngursc1hp6ont8263e9ah519e',
    registerPath: 'https://members.avfc.co.uk/register',
    loginPath: 'https://members.avfc.co.uk/',
    logoutPath: 'https://members.avfc.co.uk/partner/logout',
    tokenKey: 'token',
    cookiePath: '/',
    cookieDomain: '.avfc.co.uk',
  },

  wordpress: {
    pages: {
      home: '/section?slug=home',
      faq: '/section?slug=faq',
      subscribe: '/section?slug=subscribe',
      fixtures: '/section?slug=fixtures',
      playlists: '/section?slug=playlists',
      browse: '/section?slug=browse',
      video: '/section?slug=video',
      paymentComplete: '/section?slug=payment-complete',
    },
    commonData: '/common_field?slug=common-fields'
  }
};
