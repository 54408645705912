import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import MobileDetect from 'mobile-detect';
import {GenericPaymentResponse} from 'amg-fe-angular/types/payments/user-auth.type';
import { configs } from './app/core/configs';

declare var window;

if (environment.production) {
  enableProdMode();
}

const mobileDetect = new MobileDetect(window.navigator.userAgent);
window.isMobileDevice = mobileDetect.mobile();
if (window.isMobileDevice) {
  document.body.parentElement.classList.add('mobile-device');
}

const streamPaymentSessionData: GenericPaymentResponse =
  window.localStorage.getItem('StreamPaymentSessionData') ? JSON.parse(window.localStorage.getItem('StreamPaymentSessionData')) : null;
const currentUrl = new URL(window.location.href);

if (currentUrl.searchParams.get('payments-sso-login') && streamPaymentSessionData?.JwtTokenCacheKey) {

  const ssoUrl = new URL(currentUrl.searchParams.get('returnUrl'));
  ssoUrl.searchParams.set('token', streamPaymentSessionData.JwtTokenCacheKey);

  window.location.replace(ssoUrl.href);

} else {

  if (currentUrl.searchParams.get('payments-sso-login')) {
    const ssoUrl = new URL(currentUrl.searchParams.get('returnUrl'));


    if (ssoUrl.pathname.startsWith('/sso/start/')) {

      const pathSplit = ssoUrl.pathname.split('/');


      if (pathSplit[3]) {
        const decodedPath = atob(pathSplit[3].substring(0, pathSplit[3].length - 1));
        if (decodedPath.startsWith('returnurl:/sso/startbasket/')) {
          const search = currentUrl.searchParams.get('returnUrl').replace('~/?lang=en', '');

          document.location.href = `${configs.sso.loginPath}?returnUrl=${search}`;
        }
      }
    }
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
}


