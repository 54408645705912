import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {SharedModule} from './shared/shared.module';
import {HeaderModule} from './modules/header/header.module';
import {FooterModule} from './modules/footer/footer.module';
import {CookieService} from 'ngx-cookie-service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    FormsModule,
    SharedModule,
    HeaderModule,
    FooterModule,
    // Disable Service worker as we don't want to show 'Add to Home screen' due to Native Mobile applications
    // ServiceWorkerModule.register('ngsw-worker.js',
    //   { enabled: environment.production, registrationStrategy: 'registerImmediately' })
  ],
  providers:[CookieService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
