import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CommonData} from '../types/wordpress/common-data.type';
import {Labels} from '../types/labels.type';
import {configs} from '../configs';
import {WordpressService as WordpressServiceAmg} from 'amg-fe-angular/services/wordpress.service';

@Injectable({
  providedIn: 'root'
})
export class WordpressService extends WordpressServiceAmg {

  private cacheCommonLabels: { [t: string]: ReplaySubject<Labels> };

  constructor(httpClient: HttpClient, @Inject(PLATFORM_ID) platform: any) {
    super(httpClient, platform);
    super.init(
      environment.wordpress.api,
      `${environment.wordpress.api}${configs.wordpress.commonData}`);
    this.cacheCommonLabels = {};
  }

  public getCommonLabels(propertyName: 'labels' | 'texts'): Observable<Labels> {
    if (!this.cacheCommonLabels[propertyName]) {

      this.cacheCommonLabels[propertyName] = new ReplaySubject<Labels>(1);

      super.getData<CommonData>(this.commonDataUrl).subscribe((value: CommonData) => {

        if (value && value[propertyName]) {

          const labels: Labels = {};

          value[propertyName].forEach((element) => {

            labels[element.key] = element.value;
          });

          this.cacheCommonLabels[propertyName].next(labels);
        }
      });
    }

    return this.cacheCommonLabels[propertyName].asObservable();
  }
}
