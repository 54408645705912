import {Component, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {configs} from '../../../../../core/configs';
import { UserAuthSsoUrbanService } from '../../user-auth-sso-urban.service';
import {SubSink} from 'subsink';

@Component({
  selector: 'app-login-btn',
  templateUrl: './login-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginBtnComponent implements OnInit, OnDestroy {

  @Input() className = 'btn btn-dark';
  loginUrl: string;

  private subSink: SubSink;

  constructor(
    private router: Router,
    private userAuthSsoUrbanService: UserAuthSsoUrbanService,
  ) {
    this.subSink = new SubSink();
  }

  ngOnInit(): void {
    this.generateLoginUrl();

    this.subSink.sink = this.router.events
      .subscribe((e: any) => {
        if(e instanceof NavigationEnd){
          this.generateLoginUrl();
        }
      });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  public onClickLogin($event: Event) {
    $event.preventDefault();

    this.userAuthSsoUrbanService
      .doLogout()
      .subscribe((logoutSuccessful) => {
        if (logoutSuccessful) {
          window.location.href = this.loginUrl;
        }
      });
  }

  private generateLoginUrl() {
    this.loginUrl = `${configs.sso.loginPath}?returnUrl=${window.location.href}`;
  }
}
