import {Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';
import {configs} from '../../../../../core/configs';

@Component({
  selector: 'app-signin-btn',
  templateUrl: './signin-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninBtnComponent implements OnInit {
  @Input() className = 'btn btn-dark';
  public registerLink: string;

  constructor() {
  }

  ngOnInit() {

    const registerUrl = new URL(configs.sso.registerPath);

  //  registerUrl.searchParams.set('returnUrl', window.location.href);

    this.registerLink = registerUrl.href;
  }

}
