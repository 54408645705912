import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent} from '@angular/router';
import {DOCUMENT, Location, registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {WordpressService} from './core/services/wordpress.service';
import localeAr from '@angular/common/locales/ar';
import {UserAuthSsoService} from 'amg-fe-angular/services/user-auth/user-auth-sso.service';
import {CssVarsUtils} from 'amg-fe-angular/utils/css-vars-utils';
import {CookieService} from 'ngx-cookie-service';

import {UserAuthSsoUrbanService} from './shared/components/user-auth/user-auth-sso-urban.service'

registerLocaleData(localeAr);

// declare const AMGTranslator;

declare var window;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly appLang = window.appLang || 'en';

  public appReady = false;
  private routerSubscription: Subscription;
  private userStateSubscription: Subscription;
  private commonFieldsWordpressSubscription: Subscription;
  public isLoading = false;

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private httpClient: HttpClient,
              private wordpressService: WordpressService,
              private activatedRoute: ActivatedRoute,
              private renderer: Renderer2,
              private location: Location,
              private cd: ChangeDetectorRef,
              //private userAuthGreen4Service: UserAuthGreen4Service,
              private userAuthService: UserAuthSsoService,
              private userAuthSsoUrbanService: UserAuthSsoUrbanService,
              private cookieService: CookieService,
  ) {

  }

  ngOnInit(): void {

    this.renderer.removeClass(this.document.body.parentElement, 'close-top-menu');

    this.appReady = false;
    this.cd.markForCheck();


    /*
    this.userStateSubscription = this.userAuthService.userStateObservable.subscribe(userState => {
      if (userState) {
        if (userState.state === UserState.loggedIn) {
          this.renderer.addClass(document.body.parentElement, 'logged-in');
        } else {
          this.renderer.removeClass(document.body.parentElement, 'logged-in');
        }
      }
    });
    */

    // retrieve Common data
    this.wordpressService.getCommonData().subscribe(data => {
      if (data) {
        this.userAuthService.streamPaymentsInit({language: this.appLang}, () => {
          this.appReady = true;
          this.cd.markForCheck();
        });
      }
    });


    CssVarsUtils.initScreenSizesOnRoot();

    let currentRoute = '';
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
      .subscribe((event: NavigationStart | NavigationEnd) => {

        if (event instanceof NavigationStart) {

          if (currentRoute === event.url.split('/')[1]) {
            this.renderer.addClass(this.document.body.parentElement, 'same-page-navigation');
          } else {
            this.renderer.removeClass(this.document.body.parentElement, 'same-page-navigation');
          }
        } else {
          currentRoute = event.urlAfterRedirects.split('/')[1];
          this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
          console.log('asdsa')
          this.sendGoogleAnalyticsData(event);

          this.setLangOnPath();
        }
      });

  }

  private setLangOnPath(): void {
    if (this.appLang) {
      const url = new URL(window.location.href);
      url.searchParams.set('lang', this.appLang);
      this.location.replaceState(url.pathname + url.search);
    }
  }

  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(this.document.body.parentElement, htmlAttribute, page);
    }
  }

  private sendGoogleAnalyticsData(event: NavigationEnd): void {
    // not needed anymore?? (maybe)
    /*
        if ((window as any).ga && configs.google?.gtm) {

          console.log('GA');

          (window as any).ga('set', 'page', event.urlAfterRedirects);
          (window as any).ga('send', 'pageview');
        }


        if (configs.google?.gtm && (window as any).gtag) {

          console.log('google')

          window.gtag('page', configs.google.gtm, {
            pageName: event.urlAfterRedirects,
          });
        }*/

    /* if (configs.google?.gtm) {

       console.log('dataLayer');
       window.dataLayer = window.dataLayer || [];
       window.dataLayer.push({
         'event': 'Pageview',
         'pageUrl': event.urlAfterRedirects,
       });
     }*/

  }

  ngOnDestroy(): void {

    this.appReady = false;
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }

    if (this.commonFieldsWordpressSubscription) {
      this.commonFieldsWordpressSubscription.unsubscribe();
    }

    if (this.userStateSubscription) {
      this.userStateSubscription.unsubscribe();
    }

  }
}
