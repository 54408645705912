// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  fixtures: {
    /*
    hull: 3000101
    leeds: 3000582
    villa: 3000221
     */
    url:'https://staging.api.streamplay.streamamg.com/fixtures/football/p/3000221/',
    // url: 'https://api.streamplay.streamamg.com/fixtures/football/p/3000221/',
    contentPageSize: 10
  },

  wordpress: {
    api: 'https://wp-devvilla.streamamg.com/wp-json/wpa/v1'
  },

  // base Url where the website is deployed, used for Payment integration template
  baseUrl: 'https://devvilla.streamamg.com',
  streamPayments: {
    // domain: 'devvillapayments.streamamg.com/'
    domain: 'astonvillafcpayments.streamamg.com/'
  }
};
