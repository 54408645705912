import {Injectable} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service'
import {GenericPaymentResponse} from 'amg-fe-angular/types/payments/user-auth.type';
import {UserAuthSsoService} from 'amg-fe-angular/services/user-auth/user-auth-sso.service';
import {configs} from '../../../core/configs';
import {environment} from '../../../../environments/environment';


export interface LoginResponse {
  Token?: string;
  Valid: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthSsoUrbanService {
  public readonly appLang = (window as any).appLang || 'en';

  private jwtTokenUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private userAuthService: UserAuthSsoService
  ) {
    const lastAuthUser = this.getLastAuthUser();
    const tokenKey = this.cookieService.get(configs.sso.tokenKey) || '';
    if (lastAuthUser) { // user is logged in on the member portal
      if (tokenKey.length === 0) { // user is logged out on the video portal
        const idToken = this.cookieService.get(`CognitoIdentityServiceProvider.${configs.sso.cognito_application_id}.${lastAuthUser}.idToken`);
        if (idToken) {
          this.initLogin(idToken);
        }
      }
    } else { // user is logged out on the member portal
      if (tokenKey.length !== 0) { // user is logged in on the video portal
        this.initLogout();
      }
    }

    const streamPaymentSessionDataString = localStorage.getItem('StreamPaymentSessionData');
    if (streamPaymentSessionDataString && streamPaymentSessionDataString.length > 0) {
      const streamPaymentSessionData = JSON.parse(streamPaymentSessionDataString);
      if (parseInt(streamPaymentSessionData.CurrentCustomerSessionStatus, 10) === 0) {
        this.initLogout();
      }
    }

    this.activatedRoute.queryParams
      .subscribe(params => {
        if (params.logout) {
          this.initLogout();
        }
        if (params.token) {
          this.initLogin(params.token);
        }
      });
  }

  private login(ngToken: string): Observable<GenericPaymentResponse> {

    return new Observable<GenericPaymentResponse>(observer => {

      try {

          if (ngToken) {

              this.userAuthService.streamPaymentsInit({
                jwtToken: ngToken,
                language: this.appLang
              }, (resp) => {
                //
                // const paramsAuth = new HttpParams().set('tokenpayload', resp.JwtTokenCacheKey).set('token', resp.JwtTokenCacheKey);
                //
                // this.httpClient.request('get', configs.streamPayments.protocol + environment.streamPayments.domain + configs.streamPayments.urls.start, {params: paramsAuth})
                //   .subscribe((response: any) => {
                //
                //   });

                observer.next(resp);
                observer.complete();
              });
            } else {
              observer.next(null);
              observer.complete();
          }
      } catch (error) {
        console.warn('Error:', error);
        observer.next(null);
        observer.complete();
      }
    });
  }

  public isLoggedIn(): boolean {
    return this.getLastAuthUser().length !== 0;
  }

  private getLastAuthUser(): string {
    return this.cookieService.get(`CognitoIdentityServiceProvider.${configs.sso.cognito_application_id}.LastAuthUser`) || '';
  }

  private initLogin(token: string) {
    this.doLogin(token)
    .subscribe((loginSuccess) => {
      if (loginSuccess) {
        this.router.navigate([], { queryParams: { token: null }, queryParamsHandling: 'merge' });
      }
    });
  }

  private initLogout() {
    this.doLogout()
      .subscribe((logoutSuccess) => {
        if (logoutSuccess) {
          this.router.navigate([], { queryParams: { logout: null }, queryParamsHandling: 'merge' });
        }
      });
  }

  public doLogin(token: string): Observable<boolean> {
    return new Observable<boolean>(observer => {

      try {
        this.login(token)
          .subscribe(response => {
            this.jwtTokenUrl = token;
            this.cookieService.set(configs.sso.tokenKey, this.jwtTokenUrl);

            observer.next(true);
            observer.complete();
          });

        observer.next(null);
        observer.complete();
      } catch (error) {

        console.warn('Error:', error);
        observer.next(null);
        observer.complete();
      }
    });
  }

  public doLogout(): Observable<boolean> {
    return new Observable<boolean>(observer => {

      try {

        this.userAuthService
          .streamPaymentsLogOut(null, () => {
            this.jwtTokenUrl = '';
            localStorage.removeItem('StreamPaymentSessionData');

            const lastAuthUser = this.getLastAuthUser();
            if (lastAuthUser.length > 0) {
              [
                'accessToken',
                'clockDrift',
                'idToken',
                'refreshToken',
              ]
              .forEach((key: string) => {
                this.cookieService.delete(`CognitoIdentityServiceProvider.${configs.sso.cognito_application_id}.${lastAuthUser}.${key}`, configs.sso.cookiePath, configs.sso.cookieDomain);
              });
              this.cookieService.delete(`CognitoIdentityServiceProvider.${configs.sso.cognito_application_id}.LastAuthUser`, configs.sso.cookiePath, configs.sso.cookieDomain)
            }
            this.cookieService.delete(configs.sso.tokenKey);

            observer.next(true);
            observer.complete();
          });

        observer.next(null);
        // observer.complete();
      } catch (error) {

        console.warn('Error:', error);
        observer.next(null);
        // observer.complete();
      }
    });
  }
}
