import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from './footer.component';
import {RouterModule} from '@angular/router';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {SharedModule} from '../../shared/shared.module';



@NgModule({
  declarations: [FooterComponent],
  exports: [
    FooterComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        LazyLoadImageModule,
        SharedModule
    ]
})
export class FooterModule { }
