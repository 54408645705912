import {Component, OnInit, ViewEncapsulation, Input, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {configs} from '../../../../../core/configs';

@Component({
  selector: 'app-logout-btn',
  templateUrl: './logout-btn.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutBtnComponent implements OnInit {

  @Input() className = 'btn btn-dark';
  logoutUrl: string;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.generateLogoutUrl();
    this.changeDetectorRef.markForCheck();

    this.router.events
      .subscribe((e: any) => {
        if(e instanceof NavigationEnd){
          this.generateLogoutUrl();
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  private generateLogoutUrl() {
    this.logoutUrl = `${configs.sso.logoutPath}?returnUrl=${window.location.href}&logout=true`;
  }
}
