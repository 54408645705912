<header [class.menu-opened]="menuOpened" #headerElementRef>
  <div class="top-header">

    <div *ngFor="let item of commonData.top_bar_links">
      <ng-container *ngIf="item.link | isExternalLink; else internal">
        <a class="external" [attr.href]="item.link" target="_blank" [textContent]="item.label"></a>
      </ng-container>
      <ng-template #internal>
        <a routerLinkActive="selected" [routerLink]="item.link" [textContent]="item.label"></a>
      </ng-template>
    </div>
  </div>

  <div class="main-header">
    <div [class.opened]="searchOpened" class="search-container mobile-overlay">
      <div (click)="closeSearch()" class="close-btn"></div>
      <form [formGroup]="form" autocomplete="off" (ngSubmit)="submitSearch($event, headerSearchBar)">
        <div class="search-content">
          <label class="search-lbl" [textContent]="'search.label' | label"></label>
          <div class="field-container">
            <label #searchLabelMobile class="place-holder-label mobile-lbl" [textContent]="'search.placeholder' | label"></label>
            <input #headerSearchBar formControlName="search" (ngModelChange)="showOrHideManually($event)" type="text"/>
            <input type="submit"/>
          </div>
        </div>
      </form>
    </div>

    <div class="logo">
      <a routerLink="/home">home</a>
    </div>
    <div class="logo-avfc">
      <a [attr.href]="commonData.avfc_link" target="_blank">AVFC</a>
    </div>
    <div (click)="toggleSearch(headerSearchBar)" class="search-icon"></div>
    <div (click)="openMenu()" class="menu-mobile-icon"></div>
    <div (click)="closeMenu()" class="close-menu-overlay"></div>
    <div id="header-menu">

      <div (click)="closeMenu()" class="close-btn"></div>

      <div id="header-menu-content">
        <nav>
          <div *ngFor="let item of commonData.menu">
            <ng-container *ngIf="item.link | isExternalLink; else internal">
              <a class="external" [attr.href]="item.link" target="_blank" [textContent]="item.label"></a>
            </ng-container>
            <ng-template #internal>
              <a routerLinkActive="selected" [routerLink]="item.link" [textContent]="item.label"></a>
            </ng-template>
          </div>
        </nav>
        <div class="desktop-search-container">
          <div [class.opened]="searchOpened" class="search-container mobile-overlay desk-view">
            <div (click)="closeSearch()" class="close-btn"></div>
            <form [formGroup]="form" autocomplete="off" (ngSubmit)="submitSearch($event, headerSearchBar)">
              <div class="search-content">
                <div class="field-container">
                  <label #searchLabelDesktop class="place-holder-label" [textContent]="'search.placeholder' | label"></label>
                  <input #headerSearchBar formControlName="search" (ngModelChange)="showOrHideManually($event)" id="header-search-bar" type="text"/>
                  <input type="submit"/>
                </div>
              </div>
            </form>
          </div>
          <div (click)="toggleSearch(headerSearchBar)" [class.opened]="searchOpened" class="search-icon desk-view"></div>
        </div>
        <div class="secondary-menu">
          <ng-template #notLogged>
            <div class="button">
              <app-login-btn className="" (click)="closeMenu(true)"></app-login-btn>
            </div>
          </ng-template>
          <ng-container *ngIf="isLoggedIn; else notLogged">
            <div class="button button-my-account">
              <a class="btn-dark" [href]="myAccountLink" [textContent]="'my-account' | label"></a>
            </div>
            <div class="button">
              <app-logout-btn className="" (click)="closeMenu(true)"></app-logout-btn>
            </div>
          </ng-container>

          <!--<div id="amg-translator-list-select-container" class="notranslate">
            <div id="amg-translator-list-select-content" [class.opened]="translateOpened">
              <span class="selected-lang" data-value="en" (click)="toggleTranslate()">English</span>
              <ul id="amg-translator-list-select">
                <li class="selected" data-value="en">English</li>
                <li data-value="ar">العربية</li>
              </ul>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>

</header>
