import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module')
      .then(m => m.HomeModule)
  },
  {
    path: 'fixtures',
    loadChildren: () => import('./pages/fixtures/fixtures.module')
      .then(m => m.FixturesModule)
  },
  {
    path: 'browse',
    loadChildren: () => import('./pages/browse/browse.module')
      .then(m => m.BrowseModule)
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./pages/subscribe/subscribe.module')
      .then(m => m.SubscribeModule)
  },
  {
    path: 'video',
    loadChildren: () => import('./pages/video/video.module')
      .then(m => m.VideoModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faq/faq.module')
      .then(m => m.FaqModule)
  },
  {
    path: 'payment-complete',
    loadChildren: () => import('./pages/payment-complete/payment-complete.module')
      .then(m => m.PaymentCompleteModule)
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
